<template>
  <div class="container my-3">
    <div class="row">
      <div class="col-md-3 col-xl-2 no-print">
        <div class="card">
          <div class="card-header">
            <div class="card-title mb-0">Profile Settings</div>
          </div>

          <div class="list-group list-group-flush" role="tablist">
            <a
              class="list-group-item list-group-item-action active"
              data-toggle="list"
              id="accountTab"
              href="#account"
              role="tab"
              @click="undoHash"
              >Account</a
            >
            <a
              class="list-group-item list-group-item-action"
              data-toggle="list"
              id="passwordTab"
              href="#password"
              role="tab"
              @click="undoHash"
              >Password</a
            >
            <a
              class="list-group-item list-group-item-action"
              data-toggle="list"
              id="reservationsTab"
              href="#reservations"
              role="tab"
              @click="goRes"
              >Reservations</a
            >
            <a
              class="list-group-item list-group-item-action"
              data-toggle="list"
              href="#!"
              role="tab"
              @click="goHome"
              >Back To Home Page</a
            >
          </div>
        </div>
      </div>
      <div class="col-md-9 col-xl-10 minHeight">
        <div class="tab-content mb-0 pb-0">
          <div class="tab-pane fade show active" id="account" role="tabpanel">
            <ProfileForm
              :externalUserId="externalUserId"
              :tenantId="tenantId"
              :customer="customer"
              :confirmEmail="confirmEmail"
              :loading="profileLoading"
            />
          </div>
          <div class="tab-pane fade" id="password" role="tabpanel">
            <ProfilePasswordChangeForm
              :externalUserId="externalUserId"
              :tenantId="tenantId"
            />
          </div>
          <div class="tab-pane fade" id="reservations" role="tabpanel">
            <div class="card">
              <div class="card-header">
                <div class="card-title mb-0">
                  {{ showReservation ? "Reservation Details" : "Reservations" }}
                </div>
              </div>
              <div class="card-body">
                <ul
                  class="nav nav-tabs mb-2"
                  id="resTabs"
                  role="tablist"
                  v-if="!showReservation"
                >
                  <li class="nav-item" role="tab">
                    <a
                      class="nav-link active"
                      id="currentResTab"
                      data-toggle="tab"
                      href="#currentRes"
                      aria-controls="currentRes"
                      aria-selected="true"
                      >Current & Upcoming Reservations</a
                    >
                  </li>
                  <li class="nav-item" role="tab">
                    <a
                      class="nav-link"
                      id="pastResTab"
                      data-toggle="tab"
                      href="#profile"
                      aria-controls="profile"
                      aria-selected="false"
                      >Past Reservations</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="currentRes"
                    role="tabpanel"
                    aria-labelledby="currentResTab"
                  >
                    <ProfileReservationsList
                      :reservations="currentReservations"
                      @loadReservations="loadCurrentReservations"
                      @setReset="setReset"
                      :reset="reset"
                      :loading="currentReservationsLoading"
                      :customerId="customer.customerId"
                      :externalUserId="+externalUserId"
                      noResMessage="No current reservations to show..."
                      @showRes="showRes"
                      :showReservation="showReservation"
                    />
                  </div>
                  <div
                    class="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="pastResTab"
                  >
                    <ProfileReservationsList
                      :reservations="pastReservations"
                      @loadReservations="loadPastReservations"
                      @setReset="setReset"
                      :reset="reset"
                      :loading="pastReservationsLoading"
                      :customerId="customer.customerId"
                      :externalUserId="+externalUserId"
                      noResMessage="No past reservations to show..."
                      @showRes="showRes"
                      :showReservation="showReservation"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileForm from "@/components/profile/ProfileForm.vue";
import ProfilePasswordChangeForm from "@/components/profile/ProfilePasswordChangeForm.vue";
import ProfileReservationsList from "@/components/profile/ProfileReservationsList.vue";
import ProfileService from "@/services/ProfileService.js";
export default {
  name: "CustomerProfile",
  title: "Customer Profile",
  props: {
    externalUserId: String
  },
  components: {
    ProfileForm,
    ProfilePasswordChangeForm,
    ProfileReservationsList
  },
  data() {
    return {
      customer: {},
      currentReservations: {},
      pastReservations: {},
      confirmEmail: "",
      profileLoading: true,
      currentReservationsLoading: true,
      pastReservationsLoading: true,
      reset: false,
      showReservation: false
    };
  },
  watch: {
    $route(to) {
      if (
        to.hash &&
        (to.hash === "#reservations" || to.hash === "#reservations?r=t")
      ) {
        document.getElementById("accountTab").className =
          "list-group-item list-group-item-action";
        document.getElementById("passwordTab").className =
          "list-group-item list-group-item-action";
        document.getElementById("reservationsTab").className += " active";
        document.getElementById("account").className = "tab-pane fade";
        document.getElementById("password").className = "tab-pane fade";
        document.getElementById("reservations").className += " show active";
      } else {
        document.getElementById("reservationsTab").className =
          "list-group-item list-group-item-action";
        document.getElementById("passwordTab").className =
          "list-group-item list-group-item-action";
        document.getElementById("accountTab").className += " active";
        document.getElementById("reservations").className = "tab-pane fade";
        document.getElementById("password").className = "tab-pane fade";
        document.getElementById("account").className += " show active";
      }
      this.setReset(true);
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    showRes(x) {
      this.showReservation = x;
    },
    async loadCustomer() {
      try {
        let profileService = new ProfileService(this.tenantId);
        let customerResponse = await profileService.getCustomerByExternalUserId(
          this.externalUserId
        );

        let loadedCustomer = customerResponse.data;
        if (loadedCustomer) {
          const {
            id,
            prefixName,
            firstName,
            middleName,
            lastName,
            suffixName,
            email,
            comments,
            customerStatus,
            userId,
            timezoneId,
            createdByAdmin
          } = loadedCustomer;
          this.customer = {
            customerId: id,
            prefixName,
            firstName,
            middleName,
            lastName,
            suffixName,
            email,
            comments,
            customerStatus,
            userId,
            timezoneId,
            createdByAdmin
          };
          this.confirmEmail = email;
          if (
            loadedCustomer.addresses.filter(x => x.addressTypeId === 1).length >
            0
          ) {
            const address = loadedCustomer.addresses.filter(
              x => x.addressTypeId === 1
            )[0];
            const {
              id,
              street1,
              street2,
              city,
              state,
              zipcode,
              country
            } = address;
            this.customer = {
              ...this.customer,
              addressId: id,
              street1,
              street2,
              city,
              state: state.trim(),
              zipCode: zipcode.trim(),
              country
            };
          }
          if (
            loadedCustomer.customerPhoneNumbers.filter(
              x => x.isPrimary && x.phoneNumberTypeId === 1
            ).length > 0
          ) {
            const primary = loadedCustomer.customerPhoneNumbers.filter(
              x => x.isPrimary && x.phoneNumberTypeId === 1
            )[0];
            this.customer.primaryPhoneNumber = primary.number;
            this.customer.primaryPhoneNumberId = primary.id;
          }
          if (
            loadedCustomer.customerPhoneNumbers.filter(
              x => x.phoneNumberTypeId === 2
            ).length > 0
          ) {
            const alternate = loadedCustomer.customerPhoneNumbers.filter(
              x => x.phoneNumberTypeId === 2
            )[0];
            this.customer.alternatePhoneNumber = alternate.number;
            this.customer.alternatePhoneNumberId = alternate.id;
          }
          if (loadedCustomer.user && loadedCustomer.user.username) {
            this.customer.username = loadedCustomer.user.username;
          }
          await this.loadCurrentReservations({
            pageNumber: 1,
            itemsPerPage: 10
          });
          await this.loadPastReservations({ pageNumber: 1, itemsPerPage: 10 });
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Profile could not be loaded, please refresh",
            layer: "public"
          });
        }
      } catch (err) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
      } finally {
        this.profileLoading = false;
        this.currentReservationsLoading = false;
        this.pastReservationsLoading = false;
      }
    },
    async loadCurrentReservations(ctx) {
      if (this.customer.customerId) {
        this.currentReservationsLoading = true;
        const profileService = new ProfileService(this.tenantId);
        return profileService
          .getCurrentReservationsForCustomer(this.customer.customerId, {
            pageNumber: ctx.pageNumber,
            itemsPerPage: ctx.itemsPerPage
          })
          .then(response => {
            this.currentReservations = response.data;
          })
          .finally(() => {
            this.currentReservationsLoading = false;
          });
      }
    },
    async loadPastReservations(ctx) {
      if (this.customer.customerId) {
        this.pastReservationsLoading = true;
        const profileService = new ProfileService(this.tenantId);
        return profileService
          .getPastReservationsForCustomer(this.customer.customerId, {
            pageNumber: ctx.pageNumber,
            itemsPerPage: ctx.itemsPerPage
          })
          .then(response => {
            this.pastReservations = response.data;
          })
          .finally(() => {
            this.pastReservationsLoading = false;
          });
      }
    },
    setReset(reset) {
      this.reset = reset;
    },
    goRes() {
      this.$router.push(this.$route.path + "#reservations").catch(() => {});
      this.setReset(true);
      this.showRes(false);
    },
    goHome() {
      this.$router.push("/").catch(() => {});
    },
    undoHash() {
      if (this.$route.hash === "#reservations")
        this.$router.push(this.$route.path).catch(() => {});
    }
  },
  mounted() {
    this.setReset(true);
    if (
      this.$route.hash === "#reservations" ||
      this.$route.hash === "#reservations?r=t"
    ) {
      document.getElementById("accountTab").className =
        "list-group-item list-group-item-action";
      document.getElementById("passwordTab").className =
        "list-group-item list-group-item-action";
      document.getElementById("reservationsTab").className += " active";
      document.getElementById("account").className = "tab-pane fade";
      document.getElementById("password").className = "tab-pane fade";
      document.getElementById("reservations").className += " show active";
    }
    this.loadCustomer();
  }
};
</script>

<style scoped>
.tab-content {
  border: none !important;
  background: none !important;
}
.tab-pane {
  background: none !important;
}
.card {
  border: 1px solid #ccc !important;
}
.list-group-item.active {
  background-color: #0f78a8 !important;
  border-color: #0f78a8 !important;
}
.minHeight {
  min-height: 510px;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>
