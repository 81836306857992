<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title mb-0">Cancel Reservation</div>
    </div>
    <div class="card-body">
      <div v-if="!reservation" class="my-3 text-center">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
      <div v-else>
        <div class="pb-2">
          <button
            type="button"
            class="btn btn-md btn-primary"
            @click="backToDetails"
          >
            Back to Reservation Details
          </button>
        </div>
        <div class="row my-4">
          <div class="col-md-4">
            <img
              :src="reservation.locationImageUrl"
              :alt="reservation.parkName + ' Landscape'"
              class="w-100"
            />
          </div>
          <div class="col-md-8 p-3">
            <div class="card-block">
              <div class="pl-4">
                <router-link
                  :to="`/location/${reservation.locationId}`"
                  class="h2 text-primary mb-0"
                  >{{ reservation.parkName }}</router-link
                >
                <br />
                <p class="label pt-3">Reservation Number:</p>
                {{ reservation.reservationNumber }}
                <br />
                <p class="label">Reservation Status:</p>
                {{ reservation.reservationStatus | formatEnum }}
              </div>
              <div class="row mt-4">
                <div class="border-right col-6">
                  <div class="data-value">{{ reservation.numberOfAdults }}</div>
                  <div class="data-label">Number of People</div>
                </div>
                <div class="col-6">
                  <div class="data-value">
                    {{ reservation.numberOfVehicles }}
                  </div>
                  <div class="data-label">Number of Vehicles</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProfileReservationInfo :reservation="reservation" />
        <div class="form-row justify-content-center">
          <div class="col col-6 text-left">
            <div class="info-label">Confirmation Number</div>
          </div>
          <div class="col col-6 text-right">
            <div class="info-label">{{ reservation.orderNumber }}</div>
          </div>
        </div>
        <div class="form-row justify-content-center">
          <div class="col col-6 text-left">
            <div class="info-label">Transaction Date</div>
          </div>
          <div class="col col-6 text-right">
            <div class="info-label">{{ reservation.transactionDate }}</div>
          </div>
        </div>
        <div class="form-row justify-content-center">
          <div class="col col-6 text-left">
            <div class="info-label">Park Use Fee</div>
          </div>
          <div class="col col-6 text-right">
            <div class="info-label">
              {{ parkUseFee | formatCurrency }}
            </div>
          </div>
        </div>
        <div class="form-row justify-content-center">
          <div class="col col-6 text-left">
            <div class="info-label">Cancellation Fee</div>
          </div>
          <div class="col col-6 text-right">
            <div class="info-label">{{ cancellationFee | formatCurrency }}</div>
          </div>
        </div>
        <div class="form-row justify-content-center">
          <div class="col col-6 text-left">
            <div class="info-label">
              Transaction Fee
              <sub>(Non Refundable)</sub>
            </div>
          </div>
          <div class="col col-6 text-right">
            <div class="info-label">{{ transactionFee | formatCurrency }}</div>
          </div>
        </div>
        <div class="form-row justify-content-center">
          <div class="col col-6 text-left">
            <div class="info-label">
              Convenience Fee
              <sub>(Non Refundable)</sub>
            </div>
          </div>
          <div class="col col-6 text-right">
            <div class="info-label">{{ convenienceFee | formatCurrency }}</div>
          </div>
        </div>
        <hr />
        <div class="form-row justify-content-center">
          <div class="col col-6 text-left">
            <div class="info-label">Refund Amount</div>
          </div>
          <div class="col col-6 text-right">
            <div class="info-label">{{ refundAmount | formatCurrency }}</div>
          </div>
        </div>
        <div class="form-row justify-content-center">
          <div class="col col-6 text-left">
            <div class="info-label">Refund Method</div>
          </div>
          <div class="col col-6 text-right">
            <div class="info-label">
              {{ reservation.paymentType | formatEnum }}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationButton
        class="btn-primary mr-2 mt-3"
        buttonText="Confirm Cancellation"
        buttonClass="btn-secondary"
        confirmationText="Are you sure you want to cancel this reservation?"
        affirmativeButton="Confirm Cancellation"
        negativeButton="CANCEL"
        @affirmative="cancelReservation"
        @negative="cancel"
      />
    </div>
  </div>
</template>

<script>
import ProfileReservationInfo from "@/components/profile/ProfileReservationInfo.vue";
import SpotReservationService from "@/services/SpotReservationService.js";
import PricingService from "@/services/PricingService.js";
import ConfirmationButton from "@/components/layout/ConfirmationButton.vue";

export default {
  name: "ProfileReservationCancel",
  components: {
    ProfileReservationInfo,
    ConfirmationButton
  },
  props: {
    customerId: Number,
    reservation: { type: Object, default: null }
  },
  data() {
    return {
      fees: []
    };
  },
  methods: {
    backToDetails() {
      this.$emit("backToDetails");
    },
    goToParkDetails() {
      this.$router
        .push("/location/" + this.reservation.locationId)
        .catch(() => {});
    },
    async getFees() {
      const pricingService = new PricingService(this.tenantId);
      const feeResponse = await pricingService.getFees(
        {
          spotId: +this.reservation.spotId,
          startDate: this.reservation.arrivalDate,
          endDate: this.reservation.departureDate,
          numberOfAdults: this.reservation.numberOfAdults,
          numberOfVehicles: this.reservation.numberOfVehicles,
          discounts: this.reservation.discounts,
          customerId: this.customerId,
          salesChannel: 1
        },
        1
      );
      this.fees = feeResponse.data;
    },
    cancel() {
      this.$emit("cancel");
      this.$emit("backToDetails");
    },
    async cancelReservation() {
      try {
        this.$store.commit("auth/setLoading", true);
        const spotReservationService = new SpotReservationService(
          this.tenantId
        );
        spotReservationService
          .cancelReservation(this.customerId, this.reservation.id, {
            pricingRequest: {
              spotId: this.reservation.spotId,
              startDate: this.reservation.arrivalDate,
              endDate: this.reservation.departureDate,
              numberOfAdults: this.reservation.numberOfAdults
            },
            customerId: this.customerId,
            reservationId: this.reservation.id,
            feeOverrides: this.fees,
            refundOption: 1 //Standard refund
          })
          .then(response => {
            if (response.statusCode == "Success") {
              this.$router
                .push(`/reservation-cancel-receipt/${response.data.id}`)
                .catch(() => {});
            } else {
              window.scrollTo(0, 0);
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "public"
              });
            }
            this.$store.commit("auth/setLoading", false);
          });
      } catch (err) {
        window.scrollTo(0, 0);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
        this.$store.commit("auth/setLoading", false);
      }
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    parkUseFee() {
      const parkUseFee = this.reservation.reservationFees.find(
        x => x.feeType === "ParkUse" && x.amount > 0
      );
      return parkUseFee?.amount * parkUseFee?.quantity;
    },
    refundAmount() {
      return this.parkUseFee - this.cancellationFee;
    },
    cancellationFee() {
      const fee = this.fees.find(x => x.feeType === "Cancellation");
      return fee ? fee.amount * fee.quantity : 0;
    },
    transactionFee() {
      return 0;
    },
    convenienceFee() {
      return 0;
    }
  },
  mounted() {
    this.getFees();
  }
};
</script>

<style scoped>
.heading {
  font-size: x-large;
}
img {
  object-fit: cover;
  width: 100%;
  height: 200px;
  border-style: solid;
  border-color: #1078a8;
}

.data-value {
  text-align: center;
  color: #1078a8;
  font-size: x-large;
  font-weight: bolder;
}
.data-label {
  text-align: center;
  font-size: large;
  font-weight: bolder;
}
.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.info-label {
  font-size: 0.875rem;
  margin-bottom: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #000;
}
hr {
  border-top: 2px solid #555;
}
</style>
