<template>
  <div>
    <div v-if="!showReservation">
      <div class="d-flex">
        <div class="mr-auto">
          <label for="perPageSelect" class="sr-only"
            >Reservations Per Page:</label
          >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </div>
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            size="sm"
            class="mb-0"
          ></b-pagination>
        </div>
      </div>
      <div v-if="loading" class="my-3 text-center">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
      <div v-else-if="!loading && reservations.data.length === 0">
        <div class="my-3 text-center">
          {{ noResMessage }}
        </div>
      </div>
      <div v-else>
        <div
          v-for="(res, index) in reservations.data"
          :key="index"
          class="row reservationItem pt-3 mt-2 mx-1"
        >
          <div class="col-lg-3 col-md-12 text-center px-0 mb-2">
            <img v-if="res.imageUrl" :src="res.imageUrl" :alt="res.spot" />
            <div v-else class="imagePlaceholder text-center py-4">
              No Image Provided
            </div>
          </div>
          <div class="col-lg-9 col-md-12 pl-0">
            <div class="d-flex">
              <div>
                <div>
                  <div>
                    <router-link
                      :to="`/location/${res.locationId}`"
                      class="h2 text-primary"
                      >{{ res.park }}</router-link
                    >
                    <div
                      class="badge p-1 ml-2 align-top"
                      :class="getBadgeClass(res)"
                    >
                      {{ getBadgeTitle(res) }}
                    </div>
                  </div>
                  <p class="mb-0 mt-2"><b>Loop: </b> {{ res.loop }}</p>
                  <p class="mb-0">
                    <b>Spot {{ res.spot }}</b>
                    <span
                      v-if="
                        numberOfNights(res.arrivalDate, res.departureDate) > 0
                      "
                      >|
                      {{ numberOfNights(res.arrivalDate, res.departureDate) }}
                      night(s)</span
                    >
                  </p>
                  <p class="mb-0">
                    <b>{{ res.reservationNumber }}</b>
                    ({{ res.reservationStatus }})
                  </p>
                </div>
                <div class="mt-auto">
                  <p class="mb-0">
                    <b>Arrival Date:</b>
                    {{ res.arrivalDate | formatDate("MM/DD/YYYY") }} -
                    <b>Departure Date:</b>
                    {{ res.departureDate | formatDate("MM/DD/YYYY") }}
                  </p>
                </div>
              </div>
              <div class="ml-auto">
                <button
                  class="btn btn-large btn-primary"
                  type="button"
                  @click="showCustomerReservation(res.id)"
                >
                  View Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProfileReservation
      :reservationId="reservationId"
      :customerId="customerId"
      :externalUserId="externalUserId"
      v-if="showReservation"
      @hide="hideCustomerReservation"
    />
  </div>
</template>

<script>
import ProfileReservation from "@/components/profile/ProfileReservation.vue";
import moment from "moment";
export default {
  name: "ProfileReservationsList",
  components: {
    ProfileReservation
  },
  props: {
    reservations: Object,
    customerId: Number,
    loading: Boolean,
    reset: Boolean,
    externalUserId: Number,
    noResMessage: String,
    showReservation: Boolean
  },
  data() {
    return {
      pageOptions: [10, 25, 50, 100],
      reservationId: null
    };
  },
  watch: {
    reset() {
      if (this.reset === true) {
        this.$emit("showRes", false);
        this.$emit("setReset", false);
      }
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.reservations.pageNumber;
      },
      set(val) {
        this.$emit("loadReservations", {
          pageNumber: val,
          itemsPerPage: this.perPage
        });
      }
    },
    totalRows() {
      return this.reservations.totalItems;
    },
    perPage: {
      get() {
        return this.reservations.itemsPerPage;
      },
      set(val) {
        this.$emit("loadReservations", {
          pageNumber: 1,
          itemsPerPage: val
        });
      }
    }
  },
  methods: {
    getBadgeClass(res) {
      if (res.reservationStatus === "Canceled")
        return "badge p-1 ml-2 badge-danger";
      if (moment().isBefore(moment(res.arrivalDate, "MM/DD/YYYY"), "day"))
        return "badge p-1 ml-2 badge-success";
      return "";
    },
    getBadgeTitle(res) {
      if (res.reservationStatus === "Canceled") return "Canceled";
      if (moment().isBefore(moment(res.arrivalDate, "MM/DD/YYYY"), "day"))
        return "Upcoming";
      return "";
    },
    numberOfNights(arrivalDate, departureDate) {
      return moment(departureDate, "MM/DD/YYYYY").diff(
        moment(arrivalDate, "MM/DD/YYYY"),
        "days"
      );
    },
    showCustomerReservation(id) {
      this.$emit("showRes", true);
      this.reservationId = id;
    },
    hideCustomerReservation() {
      this.$emit("showRes", false);
      this.reservationId = null;
    }
  }
};
</script>

<style scoped>
.reservationItem {
  border-top: 2px solid #555;
}
.h2 {
  font-size: large;
  font-weight: bold;
}
img {
  object-fit: cover;
  width: 150px;
  height: 150px;
  border-style: solid;
  border-color: #1078a8;
}
.imagePlaceholder {
  width: 150px;
  height: 150px;
  border-color: #1078a8;
  border-style: solid;
  color: #1078a8;
  font-weight: bold;
  font-size: large;
}
</style>
