<template>
  <ValidationObserver ref="passwordChangeForm">
    <form @submit.prevent="onSubmitPassword">
      <FormErrorAlert
        v-if="passwordErrors.length > 0"
        :errors="passwordErrors"
        :formRef="this.$refs.passwordChangeForm"
      />
      <div class="card">
        <div class="card-header">
          <div class="card-title mb-0">
            Password
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <TextInput
                class="mb-2"
                rules="required"
                name="Current Password"
                id="currentPass"
                v-model="currentPass"
                type="password"
                placeholder="Enter your password"
              />
              <p>
                <a
                  class="text-primary"
                  @click="$router.push('/forgot-login').catch(() => {})"
                  >Forgot your username or password?</a
                >
              </p>
              <TextInput
                rules="required|min:8|atLeastOneLowercase|atLeastOneUppercase|atLeastOneNumber|atLeastOneSpecialCharacter"
                name="New Password"
                class="mb-2"
                id="newPass1"
                vid="pass"
                type="password"
                placeholder="Enter your new password"
                v-model="newPass1"
              />
              <TextInput
                rules="required|confirmed:pass"
                class="mb-2"
                name="Confirm New Password"
                id="newPass2"
                type="password"
                placeholder="Confirm your new password"
                v-model="newPass2"
              />
              <button type="submit" class="btn btn-lg btn-primary mt-4">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import ProfileService from "@/services/ProfileService.js";
import TextInput from "@/validation/TextInput.vue";
import { ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
export default {
  name: "ProfilePasswordChangeForm",
  props: {
    externalUserId: String,
    tenantId: String
  },
  data() {
    return {
      currentPass: "",
      newPass1: "",
      newPass2: "",
      passwordErrors: []
    };
  },
  components: {
    TextInput,
    FormErrorAlert,
    ValidationObserver
  },
  methods: {
    async onSubmitPassword() {
      this.$refs.passwordChangeForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.passwordChangeForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.passwordErrors = errors;
            this.$refs.passwordChangeForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            this.$store.commit("auth/setLoading", true);
            const profileService = new ProfileService(this.tenantId);
            const updatedPass = await profileService.changeCustomerPassword(
              this.externalUserId,
              this.currentPass,
              this.newPass1
            );
            if (updatedPass.statusCode === "Success") {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message: "Your password was changed successfully",
                layer: "public"
              });
            } else {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message:
                  "Your password change was unsuccessful. Ensure you aren't using a previous password and that your current password is correct.",
                layer: "public"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "public"
            });
          } finally {
            this.$store.commit("auth/setLoading", false);
          }
        }
      });
    }
  }
};
</script>
