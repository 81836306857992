<template>
  <div>
    <hr />
    <div class="form-row justify-content-center">
      <div class="col col-6 text-left">
        <div class="info-label">Arrival Date</div>
      </div>
      <div class="col col-6 text-right">
        <div class="info-label">{{ reservation.arrivalDate }}</div>
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="col col-6 text-left">
        <div class="info-label">Departure Date</div>
      </div>
      <div class="col col-6 text-right">
        <div class="info-label">{{ reservation.departureDate }}</div>
      </div>
    </div>
    <div class="form-row justify-content-center" v-if="!reservation.isDayUse">
      <div class="col col-6 text-left">
        <div class="info-label">Number of Days</div>
      </div>
      <div class="col col-6 text-right">
        <div class="info-label">{{ numberOfNights }}</div>
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="col col-6 text-left">
        <div class="info-label">Primary Occupant</div>
      </div>
      <div class="col col-6 text-right">
        <div class="info-label">
          {{
            reservation.primaryOccupant.firstName +
              " " +
              reservation.primaryOccupant.lastName
          }}
        </div>
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="col col-6 text-left">
        <div class="info-label">Primary Occupant Address</div>
      </div>
      <div class="col col-6 text-right">
        <div class="info-label">
          {{
            reservation.primaryOccupant.street1 +
              " " +
              customerStreet2 +
              reservation.primaryOccupant.city +
              " " +
              reservation.primaryOccupant.state +
              " " +
              reservation.primaryOccupant.zipcode
          }}
        </div>
      </div>
    </div>
    <hr />
    <div class="form-row justify-content-center">
      <div class="col col-6 text-left">
        <div class="info-label">Park Name</div>
      </div>
      <div class="col col-6 text-right">
        <div class="info-label">{{ reservation.parkName }}</div>
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="col col-6 text-left">
        <div class="info-label">Park Address</div>
      </div>
      <div class="col col-6 text-right">
        <div class="info-label">
          {{
            reservation.parkStreet1 +
              " " +
              parkStreet2 +
              reservation.parkCity +
              " " +
              reservation.parkState +
              " " +
              reservation.parkZipcode
          }}
        </div>
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="col col-6 text-left">
        <div class="info-label">Park Phone</div>
      </div>
      <div class="col col-6 text-right">
        <div class="info-label">
          {{ reservation.parkPhone | formatPhoneNumber }}
        </div>
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="col col-6 text-left">
        <div class="info-label">Loop</div>
      </div>
      <div class="col col-6 text-right">
        <div class="info-label">{{ reservation.loopName }}</div>
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="col col-6 text-left">
        <div class="info-label">Spot</div>
      </div>
      <div class="col col-6 text-right">
        <div class="info-label">{{ reservation.spotName }}</div>
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="col col-6 text-left">
        <div class="info-label">Product Class</div>
      </div>
      <div class="col col-6 text-right">
        <div class="info-label">{{ reservation.classification }}</div>
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="col col-6 text-left">
        <div class="info-label">Booking Type</div>
      </div>
      <div class="col col-6 text-right">
        <div class="info-label">{{ reservation.bookingType }}</div>
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="col col-6 text-left">
        <div class="info-label">Equipment Type</div>
      </div>
      <div class="col col-6 text-right">
        <div class="info-label">{{ reservation.equipmentType }}</div>
      </div>
    </div>
    <div class="form-row justify-content-center">
      <div class="col col-6 text-left">
        <div class="info-label">Equipment Length (ft)</div>
      </div>
      <div class="col col-6 text-right">
        <div class="info-label">{{ reservation.equipmentLength }}</div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "ProfileReservationInfo",
  props: {
    reservation: { type: Object, default: null }
  },
  computed: {
    numberOfNights() {
      return moment(this.reservation.departureDate, "MM/DD/YYYY").diff(
        moment(this.reservation.arrivalDate, "MM/DD/YYYY"),
        "days"
      );
    },
    customerStreet2() {
      return this.reservation.primaryOccupant?.street2
        ? this.reservation.primaryOccupant.street2 + " "
        : "";
    },
    parkStreet2() {
      return this.reservation.parkStreet2
        ? this.reservation.parkStreet2 + " "
        : "";
    }
  }
};
</script>

<style scoped>
.info-label {
  font-size: 0.875rem;
  margin-bottom: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #000;
}
hr {
  border-top: 2px solid #555;
}
</style>
