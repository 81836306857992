<template>
  <div>
    <div v-if="!reservation" class="my-3 text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
    <div v-else>
      <div class="row pb-2 no-print">
        <div class="col-12">
          <div
            class="alert alert-info mb-3"
            v-if="reservation.customerModifiable == false"
          >
            <div class="alert-icon">
              <i class="far fa-fw fa-bell"></i>
            </div>
            <div class="alert-message">
              This reservation can't be changed online. Please call the Park
              Office to make any changes.
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex align-items-center flex-wrap">
            <div class="mr-auto my-1">
              <button
                type="button"
                class="btn btn-md btn-primary"
                @click="backToReservations"
              >
                Back to Reservations
              </button>
            </div>
            <div class="mr-2 my-1">
              <button
                type="button"
                class="btn btn-md btn-secondary"
                @click="resendEmail"
              >
                Resend Confirmation Email
              </button>
            </div>
            <div class="my-1">
              <button
                type="button"
                class="btn btn-md btn-secondary mr-2 float-right"
                @click="printScreen"
              >
                Print
              </button>
            </div>
            <div
              v-if="
                !arrivalDateInPast &&
                  reservation.reservationStatus !== 'Canceled' &&
                  reservation.customerModifiable === true
              "
              class="mr-2 my-1"
            >
              <button
                type="button"
                class="btn btn-primary btn-md float-right"
                @click="cancel"
              >
                Cancel Reservation
              </button>
            </div>
            <div
              v-if="
                !arrivalDateInPast &&
                  reservation.reservationStatus !== 'Canceled' &&
                  reservation.customerModifiable === true
              "
              class="mr-2 my-1"
            >
              <button
                type="button"
                class="btn btn-md btn-primary float-right"
                @click="edit"
              >
                Modify
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-4">
          <img
            :src="reservation.locationImageUrl"
            :alt="reservation.parkName + ' Landscape'"
            class="w-100"
          />
        </div>
        <div class="col-md-8 p-3">
          <div class="card-block">
            <div class="pl-md-4">
              <router-link
                :to="`/location/${reservation.locationId}`"
                class="h2 text-primary mb-0"
                >{{ reservation.parkName }}</router-link
              >
              <br />
              <p class="label pt-3">Reservation Number:</p>
              {{ reservation.reservationNumber }}
              <br />
              <p class="label">Reservation Status:</p>
              {{ reservation.reservationStatus | formatEnum }}
            </div>
            <div class="row mt-4">
              <div
                class="col-4 border-right"
                v-if="
                  !arrivalDateInPast &&
                    reservation.reservationStatus !== 'Canceled'
                "
              >
                <div class="data-value">{{ reservation.daysUntilTrip }}</div>
                <div class="data-label">Days Until Trip</div>
              </div>
              <div
                class="border-right"
                :class="
                  arrivalDateInPast ||
                  reservation.reservationStatus === 'Canceled'
                    ? 'col-6'
                    : 'col-4'
                "
              >
                <div class="data-value">{{ reservation.numberOfAdults }}</div>
                <div class="data-label">Number of People</div>
              </div>
              <div
                :class="
                  arrivalDateInPast ||
                  reservation.reservationStatus === 'Canceled'
                    ? 'col-6'
                    : 'col-4'
                "
              >
                <div class="data-value">
                  {{ reservation.numberOfVehicles }}
                </div>
                <div class="data-label">Number of Vehicles</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProfileReservationInfo :reservation="reservation" />

      <div class="form-row justify-content-center">
        <div class="col col-6 text-left">
          <div class="info-label">Confirmation Number</div>
        </div>
        <div class="col col-6 text-right">
          <div class="info-label">{{ reservation.orderNumber }}</div>
        </div>
      </div>
      <div class="form-row justify-content-center">
        <div class="col col-6 text-left">
          <div class="info-label">Transaction Date</div>
        </div>
        <div class="col col-6 text-right">
          <div class="info-label">{{ reservation.transactionDate }}</div>
        </div>
      </div>
      <div class="form-row justify-content-center">
        <div class="col col-6 text-left">
          <div class="info-label">Payment Type</div>
        </div>
        <div class="col col-6 text-right">
          <div class="info-label">
            {{ reservation.paymentType | formatEnum }}
          </div>
        </div>
      </div>
      <div
        class="form-row justify-content-center"
        v-if="reservation.previousParkUseFeePaid > 0"
      >
        <div class="col col-6 text-left">
          <div class="info-label">Park Use Fee - Paid</div>
        </div>
        <div class="col col-6 text-right">
          <div class="info-label green-label">
            {{ reservation.previousParkUseFeePaid | formatCurrency }}
          </div>
        </div>
      </div>
      <div class="form-row justify-content-center">
        <div class="col col-6 text-left">
          <div class="info-label">Park Use Fee</div>
        </div>
        <div class="col col-6 text-right">
          <div class="info-label">{{ parkUseFee | formatCurrency }}</div>
        </div>
      </div>
      <div class="form-row justify-content-center" v-if="cancellationFee">
        <div class="col col-6 text-left">
          <div class="info-label">Cancellation Fee</div>
        </div>
        <div class="col col-6 text-right">
          <div class="info-label">{{ cancellationFee | formatCurrency }}</div>
        </div>
      </div>
      <div class="form-row justify-content-center" v-if="modificationFee">
        <div class="col col-6 text-left">
          <div class="info-label">Modification Fee</div>
        </div>
        <div class="col col-6 text-right">
          <div class="info-label">{{ modificationFee | formatCurrency }}</div>
        </div>
      </div>
      <div class="form-row justify-content-center">
        <div class="col col-6 text-left">
          <div class="info-label">
            Transaction Fee
            <sub>(Non Refundable)</sub>
          </div>
        </div>
        <div class="col col-6 text-right">
          <div class="info-label">{{ transactionFee | formatCurrency }}</div>
        </div>
      </div>
      <div class="form-row justify-content-center">
        <div class="col col-6 text-left">
          <div class="info-label">
            Convenience Fee
            <sub>(Non Refundable)</sub>
          </div>
        </div>
        <div class="col col-6 text-right">
          <div class="info-label">{{ convenienceFee | formatCurrency }}</div>
        </div>
      </div>
      <hr />
      <div
        class="form-row justify-content-center"
        v-if="!reservation.recentRefundAmount"
      >
        <div class="col col-6 text-left">
          <div class="info-label">Amount Paid</div>
        </div>
        <div class="col col-6 text-right">
          <div class="info-label">{{ totalAmountPaid | formatCurrency }}</div>
        </div>
      </div>
      <div
        class="form-row justify-content-center"
        v-if="reservation.recentRefundAmount"
      >
        <div class="col col-6 text-left">
          <div class="info-label">Amount Refunded</div>
        </div>
        <div class="col col-6 text-right">
          <div class="info-label green-label">
            {{ reservation.recentRefundAmount | formatCurrency }}
          </div>
        </div>
      </div>
      <a
        href="https://www2.illinois.gov/dnr/adrules/documents/17-130.pdf"
        class="float-right no-print"
        target="_blank"
        >Illinois Park Rules & Regulations</a
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ProfileReservationInfo from "@/components/profile/ProfileReservationInfo.vue";
import ProfileService from "@/services/ProfileService.js";

export default {
  name: "ProfileReservationDetails",
  components: { ProfileReservationInfo },
  props: {
    customerId: Number,
    externalUserId: Number,
    reservation: { type: Object, default: null }
  },
  methods: {
    backToReservations() {
      this.$emit("backToList");
    },
    cancel() {
      this.$emit("cancel");
    },
    edit() {
      this.$router
        .push(
          `/reservation-edit?r=${this.reservation.id}&c=${this.customerId}&l=${this.reservation.locationId}&e=${this.externalUserId}`
        )
        .catch(() => {});
    },
    feeTotal(fee) {
      if (fee.amount === 0) return 0;
      else return fee.amount * fee.quantity;
    },
    printScreen() {
      this.$store.dispatch("alert/clear");
      window.print();
    },
    async resendEmail() {
      this.$store.commit("auth/setLoading", true);
      const profileService = new ProfileService(this.tenantId);
      const response = await profileService.sendReservationConfirmationEmail(
        this.customerId,
        this.reservation.id
      );
      if (response.statusMessage === "Success") {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-info",
          message: "Reservation Confirmation email has been sent.",
          layer: "public"
        });
      }
      this.$store.commit("auth/setLoading", false);
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    fees() {
      const fees = this.reservation?.reservationFees;
      if (fees) {
        return fees.sort((a, b) => {
          return b.id - a.id;
        });
      } else {
        return [];
      }
    },
    parkUseFee() {
      const fee = this.fees.find(x => x.feeType === "ParkUse");
      return fee ? this.feeTotal(fee) : 0;
    },
    transactionFee() {
      const fee = this.fees.find(
        x => x.feeType === "NewTransaction" || x.feeType === "ModifyTransaction"
      );
      return fee && this.reservation.reservationStatus !== "Canceled"
        ? this.feeTotal(fee)
        : 0;
    },
    convenienceFee() {
      const fee = this.fees.find(x => x.feeType === "Convenience");
      return fee && this.reservation.reservationStatus !== "Canceled"
        ? this.feeTotal(fee)
        : 0;
    },
    modificationFee() {
      const fee = this.fees.find(x => x.feeType === "Modification");
      return fee && this.reservation.previousParkUseFeePaid > 0
        ? this.feeTotal(fee)
        : 0;
    },
    cancellationFee() {
      const fee = this.fees.find(x => x.feeType === "Cancellation");
      return fee ? this.feeTotal(fee) : 0;
    },
    totalAmountPaid() {
      return (
        this.parkUseFee +
        this.transactionFee +
        this.convenienceFee +
        this.modificationFee +
        this.cancellationFee -
        this.reservation.previousParkUseFeePaid
      );
    },
    arrivalDateInPast() {
      return this.reservation
        ? moment(this.reservation.arrivalDate, "MM/DD/YYYY").isBefore(moment())
        : "";
    }
  }
};
</script>

<style scoped>
img {
  object-fit: cover;
  width: 100%;
  height: 200px;
  border-style: solid;
  border-color: #1078a8;
}
.h2 {
  font-size: large;
  font-weight: bold;
}
.data-value {
  text-align: center;
  color: #1078a8;
  font-size: x-large;
  font-weight: bolder;
}
.data-label {
  text-align: center;
  font-size: large;
  font-weight: bolder;
}
.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.info-label {
  font-size: 0.875rem;
  margin-bottom: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #000;
}
.green-label {
  color: #388038 !important;
}
hr {
  border-top: 2px solid #555;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .card {
    width: 135%;
  }
  a {
    text-decoration: none;
  }
}
</style>
