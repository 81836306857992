<template>
  <ValidationObserver ref="editProfile">
    <form @submit.prevent="onSubmitCustomer">
      <FormErrorAlert
        v-if="profileErrors.length > 0"
        :errors="profileErrors"
        :formRef="this.$refs.editProfile"
      />
      <div class="card">
        <div class="card-header">
          <div class="card-title mb-0">Account Information</div>
        </div>
        <div class="card-body">
          <div v-if="loading" class="my-3 text-center">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
          <div v-else class="row">
            <div class="col-12">
              <TextInput
                class="mb-2"
                name="Username"
                id="profileUsername"
                placeholder="Enter your username"
                v-model="localCustomer.username"
                :readonly="true"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="card-title mb-0">Personal Information</div>
        </div>
        <div class="card-body">
          <div v-if="loading" class="my-3 text-center">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
          <div v-else class="row">
            <div class="col-12">
              <label for="profilePrefix">Prefix</label>
              <select
                class="form-control form-control-lg mb-2"
                id="profilePrefix"
                v-model="localCustomer.prefixName"
              >
                <option
                  v-for="(prefix, index) in prefixes"
                  :key="index"
                  :value="prefix"
                >
                  {{ prefix }}
                </option>
              </select>
              <TextInput
                class="mb-2"
                rules="required|customerName|max:30"
                name="First Name"
                id="profileFirstName"
                placeholder="Enter your first name"
                v-model="localCustomer.firstName"
              />
              <TextInput
                class="mb-2"
                rules="customerName|max:25"
                name="Middle Name"
                id="profileMiddleName"
                placeholder="Enter your middle name"
                v-model="localCustomer.middleName"
              />
              <TextInput
                class="mb-2"
                rules="required|customerName|max:30|min:2"
                name="Last Name"
                id="profileLastName"
                placeholder="Enter your last name"
                v-model="localCustomer.lastName"
              />
              <label for="profileSuffix">Suffix</label>
              <select
                class="form-control form-control-lg mb-2"
                id="profileSuffix"
                v-model="localCustomer.suffixName"
              >
                <option
                  v-for="(suffix, index) in suffixes"
                  :key="index"
                  :value="suffix"
                >
                  {{ suffix }}
                </option>
              </select>
              <button type="submit" class="btn btn-lg btn-primary mt-4">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="card-title mb-0">Address Information</div>
        </div>
        <div class="card-body">
          <div v-if="loading" class="my-3 text-center">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
          <div v-else class="row">
            <div class="col-12">
              <ValidationProvider
                rules="required"
                name="Country"
                v-slot="{ errors, ariaInput, ariaMsg }"
              >
                <label
                  @click="$refs.select.focus()"
                  :class="{ error: errors[0] }"
                  for="profileCountry"
                >
                  Country
                  <span class="error">*</span>
                </label>
                <select
                  class="form-control form-control-lg mb-2"
                  id="profileCountry"
                  v-model="localCustomer.country"
                  v-bind="ariaInput"
                >
                  <option
                    v-for="(country, index) in countries"
                    :key="index"
                    :value="country.abbreviation"
                  >
                    {{ getCountryName(country.abbreviation) }}
                  </option>
                </select>
                <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                  <li
                    v-for="(error, index) in errors"
                    :key="index"
                    class="error"
                    v-bind="ariaMsg"
                  >
                    {{ error }}
                  </li>
                </ul>
              </ValidationProvider>
              <TextInput
                class="mb-2"
                rules="required|address|max:70"
                name="Address 1"
                id="profileAddress1"
                placeholder="Enter customer street address"
                v-model="localCustomer.street1"
              />
              <TextInput
                class="mb-2"
                rules="address|max:70"
                name="Address 2"
                id="profileAddress2"
                placeholder="Enter customer street address, cont."
                v-model="localCustomer.street2"
              />
              <TextInput
                class="mb-2"
                rules="required|city|max:58"
                name="City"
                id="profileCity"
                placeholder="Enter customer city"
                v-model="localCustomer.city"
              />
              <TextInput
                class="mb-2"
                rules="required|city|max:20"
                name="State"
                id="profileState"
                placeholder="Enter customer state"
                v-model="localCustomer.state"
                v-if="isForeignAddress"
              />
              <ValidationProvider
                rules="required"
                name="State"
                v-slot="{ errors, ariaInput, ariaMsg }"
                v-else
              >
                <label
                  @click="$refs.select.focus()"
                  :class="{ error: errors[0] }"
                  for="profileState"
                >
                  State
                  <span class="error">*</span>
                </label>
                <select
                  class="form-control form-control-lg mb-2"
                  id="profileState"
                  v-model="localCustomer.state"
                  v-bind="ariaInput"
                >
                  <option
                    v-for="(state, index) in states"
                    :key="index"
                    :value="state.abbreviation"
                  >
                    {{ state.name }}
                  </option>
                </select>
                <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                  <li
                    v-for="(error, index) in errors"
                    :key="index"
                    class="error"
                    v-bind="ariaMsg"
                  >
                    {{ error }}
                  </li>
                </ul>
              </ValidationProvider>
              <TextInput
                class="mb-2"
                :rules="
                  isForeignAddress ? 'required' : 'required|min:5|zipCode'
                "
                :name="isForeignAddress ? 'ZIP/PIN Code' : 'Zip Code'"
                id="profileZipCode"
                placeholder="Enter customer zip code"
                v-model="localCustomer.zipCode"
                :mask="isForeignAddress ? '' : '#####-####'"
              />
              <button type="submit" class="btn btn-lg btn-primary mt-4">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="card-title mb-0">Contact Information</div>
        </div>
        <div class="card-body">
          <div v-if="loading" class="my-3 text-center">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
          <div v-else class="row">
            <div class="col-12">
              <TextInput
                class="mb-2"
                rules="required|email|max:256"
                name="Email Address"
                onpaste="false"
                id="profileEmail"
                type="email"
                vid="email"
                v-model="localCustomer.email"
                placeholder="Enter your email address"
              />
              <TextInput
                class="mb-2"
                rules="required|email|confirmed:email"
                name="Confirm Email Address"
                onpaste="false"
                id="confirmEmail"
                type="email"
                v-model="confirmEmail"
                placeholder="Confirm your email address"
              />
              <TextInput
                class="mb-2"
                rules="required|maskNumMin:10"
                name="Primary Phone"
                id="profilePhone"
                placeholder="Enter customer phone number"
                v-model="localCustomer.primaryPhoneNumber"
                mask="(###) ###-####"
              />
              <TextInput
                class="mb-2"
                rules="maskNumMin:10"
                name="Alternate Phone"
                id="profileAlternatePhone"
                placeholder="Enter customer alternate phone number"
                v-model="localCustomer.alternatePhoneNumber"
                mask="(###) ###-####"
              />
              <button type="submit" class="btn btn-lg btn-primary mt-4">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        ref="confirm-email-modal"
        size="sm"
        hide-header-close
        header-class="border-0"
        footer-class="border-0"
        no-close-on-backdrop
      >
        <div class="d-block text-center">
          <h4 class="text-uppercase">Confirm Email Address Change</h4>
          <h5>
            You have modified your email address on profile, Please confirm the
            change to your email address.
          </h5>
        </div>
        <template v-slot:modal-footer="{ ok }">
          <b-button class="btn" variant="primary" @click="changeEmail(ok)"
            >Save and confirm Changes</b-button
          >
          <b-button class="btn" variant="" @click="ok">Cancel changes</b-button>
        </template>
      </b-modal>
    </form>
  </ValidationObserver>
</template>

<script>
import ProfileService from "@/services/ProfileService.js";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import states from "@/store/modules/states.js";
import countries from "@/store/modules/countries.js";
import TextInput from "@/validation/TextInput.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "ProfileForm",
  components: {
    TextInput,
    ValidationProvider,
    ValidationObserver,
    FormErrorAlert
  },
  props: {
    tenantId: String,
    externalUserId: String,
    customer: Object,
    confirmEmail: String,
    loading: Boolean
  },
  data() {
    return {
      prefixes: ["Mr", "Ms", "Mrs", "Dr", "Sir"],
      suffixes: ["Jr", "Sr", "III", "Esq", "MD", "PhD"],
      profileErrors: [],
      localCustomer: {},
      emailChangeConfirmed: false
    };
  },
  watch: {
    customer() {
      this.localCustomer = JSON.parse(JSON.stringify(this.customer));
    }
  },
  computed: {
    states() {
      return states;
    },
    countries() {
      return countries;
    },
    currentEmail() {
      return this.customer.email;
    },
    isForeignAddress() {
      return this.localCustomer.country !== "US";
    }
  },
  methods: {
    changeEmail(ok) {
      ok();
      this.emailChangeConfirmed = true;
      this.customer.email = this.localCustomer.email;
      this.onSubmitCustomer();
    },
    getCountryName(abbv) {
      return this.countries.find(x => x.abbreviation === abbv).name;
    },
    async onSubmitCustomer() {
      this.$refs.editProfile.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.editProfile.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.profileErrors = errors;
            this.$refs.editProfile.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          if (
            this.currentEmail !== this.localCustomer.email &&
            !this.emailChangeConfirmed
          ) {
            this.$refs["confirm-email-modal"].show();
            return;
          }
          try {
            this.$store.commit("auth/setLoading", true);
            const profileService = new ProfileService(this.tenantId);
            this.customer.state = this.customer.state.trim();
            this.customer.zipCode = this.customer.zipCode ?? null;
            this.customer.primaryPhoneNumber =
              this.customer.primaryPhoneNumber ?? null;
            this.customer.alternatePhoneNumber =
              this.customer.alternatePhoneNumber ?? null;

            const updatedCustomer = await profileService.editCustomerProfile(
              this.localCustomer
            );

            if (updatedCustomer.statusCode == "Success") {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message: `Your profile was successfully updated`,
                layer: "public"
              });
              this.emailChangeConfirmed = false;
            } else {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Error in updating your profile, please try again",
                layer: "public"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "public"
            });
          } finally {
            this.$store.commit("auth/setLoading", false);
          }
        }
      });
    }
  }
};
</script>
