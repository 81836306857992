<template>
  <div>
    <ProfileReservationDetails
      :customerId="customerId"
      :reservation="reservation"
      :externalUserId="externalUserId"
      @backToList="backToReservations"
      @cancel="cancel"
      v-if="showDetails"
    />
    <ProfileReservationCancel
      :customerId="customerId"
      :reservation="reservation"
      @backToDetails="details"
      v-if="showCancel"
    />
  </div>
</template>

<script>
import ProfileReservationDetails from "@/components/profile/ProfileReservationDetails.vue";
import ProfileReservationCancel from "@/components/profile/ProfileReservationCancel.vue";
import ProfileService from "@/services/ProfileService.js";

export default {
  name: "ProfileReservation",
  components: {
    ProfileReservationDetails,
    ProfileReservationCancel
  },
  props: {
    customerId: Number,
    reservationId: Number,
    externalUserId: Number
  },
  data() {
    return {
      reservation: null,
      loading: false,
      showDetails: true,
      showCancel: false
    };
  },
  methods: {
    backToReservations() {
      this.$emit("hide");
    },
    async getReservationDetail() {
      if (this.reservationId) {
        this.loading = true;
        const profileService = new ProfileService(this.tenantId);
        const response = await profileService.getReservationDetailForCustomer(
          this.customerId,
          this.reservationId
        );
        const reservation = response.data;
        reservation.discounts = reservation.discounts.map(
          x => x.discountClassId
        );
        this.reservation = reservation;
        this.loading = false;
      }
    },
    cancel() {
      this.showDetails = false;
      this.showCancel = true;
    },
    details() {
      this.showDetails = true;
      this.showCancel = false;
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  created() {
    this.getReservationDetail();
  }
};
</script>
