<template>
  <button class="btn" :class="buttonClass" type="button" @click="confirm">
    <i v-if="showIcon" :class="buttonIconClass"></i> {{ buttonText }}
    <b-modal
      ref="confirm-modal"
      size="sm"
      hide-header-close
      header-class="border-0"
      footer-class="border-0"
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <h4>{{ confirmationText }}</h4>
        <h5 v-if="confirmationInfo !== ''">{{ confirmationInfo }}</h5>
      </div>
      <template v-slot:modal-footer="{ ok }">
        <b-button class="btn" variant="primary" @click="affirmative">{{
          affirmativeButton
        }}</b-button>
        <b-button class="btn" variant="" @click="negative(ok)">{{
          negativeButton
        }}</b-button>
      </template>
    </b-modal>
  </button>
</template>

<script>
export default {
  name: "ConfirmationButton",
  props: {
    buttonText: {
      type: String,
      default: ""
    },
    buttonClass: {
      type: String,
      default: ""
    },
    buttonIconClass: {
      type: String,
      default: ""
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    confirmationText: {
      type: String,
      default: ""
    },
    confirmationInfo: {
      type: String,
      default: ""
    },
    affirmativeButton: {
      type: String,
      default: ""
    },
    negativeButton: {
      type: String,
      default: ""
    }
  },
  methods: {
    affirmative() {
      this.$emit("affirmative");
      this.$refs["confirm-modal"].hide();
    },
    negative(ok) {
      ok();
      this.$emit("negative");
    },
    confirm() {
      this.$refs["confirm-modal"].show();
    }
  }
};
</script>

<style></style>
